@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
    margin: 0;
    padding: 0;
  }
}

table{
  font-size: 14px!important;
}


.App{
  height: 100vh;
  font-size: 15px;
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}
.main-modal-chakar > div:nth-child(3) > div {
  position: relative !important;
}

.main-modal-chakar input {
  border: 1px solid gray;
  border-radius: 9px;
  padding: 5px 10px;
  width: 100%;
}
.main-modal-chakar > div:nth-child(3) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  max-height: 60%;
  /* overflow-y: auto; */
  display: flex;
  width: 30% !important;
  height: var(--chakra-vh);
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  z-index: 99 !important;
  background-color: white !important;
  border: 1px solid rgb(220, 217, 217);
  /* justify-content: center; */
  transform: translate(-50%, -50%) !important;
  /* padding: 25px !important; */
}
.dark .main-modal-chakar-dark > div:nth-child(3) {
  background-color: #111c44 !important;
  border: 1px solid #04081c;
}
.menu_item_chakara > div:nth-child(2) {
  /* Your styles for the second child here */
  z-index: 10000 !important;
  /* padding: 20px !important; */
  background-color: white !important;
  font-size: 16px !important;
  color: black;
  border: 1px solid #e2e8f0;
}
.chakra-menu__menu-list > button {
}
.modalButton_close > button:first-child {
  outline: 2px solid transparent;
  outline-offset: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}
.model-svg svg {
  width: 100px;
  height: 100px;
}
.card-img div svg {
  width: 100%;
  height: 100px;
}
.chakra-select__wrapper .chakra-select__icon-wrapper {
  display: none;
}
.tags_parent .rti--container {
  width: 100%;
  border-radius: 6px 0px 0px 6px;
}
.dark .tags_input .rti--container {
  background-color: #111c44;
  border-color: #202848;
}
.dark .rti--tag {
  background: #111c44 !important;
  border-color: #202848;
}
.dark .tags_input .rti--container input {
  background-color: #111c44;
}
.dark .menu_item_chakara > div:nth-child(2) {
  background-color: #111c44 !important;
  border-color: rgb(173 181 189);
}
.menu_item_chakara > div:nth-child(2) div button {
  padding: 6px 12px;
}
.dark .menu_item_chakara > div:nth-child(2) div button:hover {
  /* Your hover styles go here */
  background-color: #333; /* Example background color on hover */
}
.chakra-modal__content-container .chakra-modal__content {
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px !important;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}
.chakra-modal__content {
  padding: 15px;
}
.modalButton_close button {
  padding-right: 15px;
  padding-top: 15px;
}
body.dark {
  background-color: #0b1437;
}
@media (min-width: 1200px) {
  .xl\:pl-\[70px\] {
    padding-left: 0px !important;
  }
}


/* CustomScrollbar.css */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* Increase the width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Optional: Change the background of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Optional: Change the background of the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Optional: Change the background of the scrollbar thumb on hover */
}
